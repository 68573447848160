import React from "react";
import ReactPlayer from "react-player";
import video from '../../videos/Renuity_coating_typ_mini_vsl.mp4';

const Video = () => {
    const createClassList = () => {
        let classList = 'ca-video';
        return classList;
    };
    return (
        <div className={createClassList()}>
            <ReactPlayer
                url={video}
                controls
                playing={true}
                // loop
                // muted
                width="100%"
                height="auto"
            />
        </div>
    );
};

export default Video;
